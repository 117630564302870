import $ from 'jquery'
import component from './_component'

function init() {}
function ready() {
  $('.js-filter-tab').each(function (tabIndex) {
    if ($(this).hasClass('is-initialized')) return

    var $tabwrap = $(this)
    var $tablist = $(this).find('.js-filter-tab_btns')
    var $tabs = $(this).find('.js-filter-tab_btn')
    var $items = $(this).find('.js-filter-tab_item')
    var focused
    var delay = 150

    $tabs.each(function (index) {
      this.index = index
    })
    $tabs.filter('[aria-selected="false"]').attr('tabindex', '-1')

    var determineOrientation = function (e) {
      var key = e.key
      var proceed = false

      if (
        key === 'ArrowLeft' ||
        key === 'ArrowRight' ||
        key === 'ArrowUp' ||
        key === 'ArrowDown'
      ) {
        e.preventDefault()
        proceed = true
      }

      if (proceed) {
        switchTabOnArrowPress(e)
      }
    }

    var switchTabOnArrowPress = function (e) {
      var pressed = e.key
      var direction = {
        ArrowLeft: -1,
        ArrowUp: -1,
        ArrowRight: 1,
        ArrowDown: 1,
      }

      $tabs.on('focus.focus-filter-tab', focusEventHandler)

      if (direction[pressed]) {
        var target = e.target
        if (target.index !== undefined) {
          if ($tabs.eq(target.index + direction[pressed])[0]) {
            $tabs.eq(target.index + direction[pressed])[0].focus()
          } else if (pressed === 'ArrowLeft' || pressed === 'ArrowUp') {
            focusLastTab()
          } else if (pressed === 'ArrowRight' || pressed == 'ArrowDown') {
            focusFirstTab()
          }
        }
      }
    }

    var deactivateTabs = function () {
      $tabs.attr('aria-selected', 'false')
      $tabs.attr('tabindex', '-1')
      $tabs.off('.focus-filter-tab', focusEventHandler)
    }

    var activateTab = function ($tab, setFocus) {
      setFocus = setFocus || true
      deactivateTabs()

      $tab.attr('aria-selected', 'true')
      $tab.removeAttr('tabindex')

      if (setFocus) {
        $tab.focus()
      }

      filterChange($tab.attr('data-filter'))
    }

    var filterChange = function (filter) {
      if (filter === 'all') {
        $items.css({ display: '' })
        $tabwrap.removeClass('is-filter')
      } else {
        $tabwrap.addClass('is-filter')
        $items.hide()
        $items.filter('[data-filter="' + filter + '"]').show()
      }
    }

    var focusFirstTab = function () {
      $tabs.eq(0).focus()
    }
    var focusLastTab = function () {
      $tabs.eq($tabs.length - 1).focus()
    }

    var focusEventHandler = function (e) {
      var target = e.target
      setTimeout(checkTabFocus, delay, target)
    }

    var checkTabFocus = function (target) {
      focused = document.activeElement
      if (target === focused) {
        activateTab($(target), false)
      }
    }

    $tabs.on('click', function (e) {
      e.preventDefault()
      activateTab($(this), false)
    })

    $tabs.on('keydown', function (e) {
      switch (e.key) {
        case 'End':
          e.preventDefault()
          activateTab($tabs.eq($tabs.length - 1))
          break
        case 'Home':
          e.preventDefault()
          activateTab($tabs.eq(0))
          break
        case 'ArrowUp':
        case 'ArrowDown':
          determineOrientation(e)
          break
      }
    })

    $tabs.on('keyup', function (e) {
      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowLeft':
          determineOrientation(e)
          break
      }
    })

    $(this).addClass('is-initialized')
  })
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
