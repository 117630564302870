import $ from 'jquery'

function init() {}
function ready() {
  const $popup = $('.js-cookie-popup')
  const $button = $popup.find('.js-cookie-popup-button')

  $button.on('click', () => {
    $popup.removeClass('is-active')
    sessionStorage.setItem('cookie-accept', '1')
  })

  if (sessionStorage.getItem('cookie-accept') !== '1') {
    $popup.addClass('is-active')
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
