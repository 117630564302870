import $ from 'jquery'
import component from './_component'

function init() {
  $(document).on('click', '.js-anchor[href^="#"]', function (e) {
    var target = $(this).attr('href')
    var $target = target === '#' ? $('html') : $(target)

    if ($target[0]) {
      e.preventDefault()

      if (target === '#') {
        $('body, html').animate(
          {
            scrollTop: $target.offset().top,
          },
          500,
          component.easeOut,
        )
      } else {
        var $header = $('.p-header')
        var headerHeight =
          $header.css('position') === 'fixed' ? $header.height() : 0
        $('body, html').animate(
          {
            scrollTop: $target.offset().top - headerHeight,
          },
          500,
          component.easeOut,
        )
      }
    }
  })
}
function ready() {}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
