import $ from 'jquery'
import component from './_component'

var mqList = [
  {
    query: '--to-md',
    name: 'sp',
  },
  {
    query: '--md-lg',
    name: 'tb',
  },
  {
    query: '--lg',
    name: 'pc',
  },
]

function init() {
  $(document).on('click', '.js-accordion_btn', function (e) {
    e.preventDefault()

    var $btn = $(this)
    var $wrap = $(this).closest('.js-accordion')
    var isClass = $wrap.attr('data-isclass') === 'true'
    var $target = $('#' + $btn.attr('aria-controls'))
    var animationType = $wrap.attr('data-animation-type')

    if ($target[0]) {
      if (animationType === 'slide') {
        if (
          $target.attr('aria-hidden') === 'false' ||
          $target.hasClass('is-open')
        ) {
          $btn.addClass('is-close')
          $btn.removeClass('is-open')
          $btn.attr('aria-expanded', false)

          $target.slideUp(1000, function () {
            if (isClass) {
              $target.addClass('is-close')
              $target.removeClass('is-open')
            } else {
              $target.attr({ 'aria-hidden': true })
            }
            $target.css({ display: '' })
          })
        } else {
          $btn.removeClass('is-close')
          $btn.addClass('is-open')
          $btn.attr('aria-expanded', true)
          if (isClass) {
            $target.addClass('is-close')
            $target.removeClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': true })
          }

          $target.slideDown(1000, function () {
            if (isClass) {
              $target.addClass('is-open')
              $target.removeClass('is-close')
            } else {
              $target.attr({ 'aria-hidden': false })
            }
            $target.css({ display: '' })
          })
        }
      } else {
        if (
          $target.attr('aria-hidden') === 'false' ||
          $target.hasClass('is-open')
        ) {
          $btn.attr('aria-expanded', false)
          if (isClass) {
            $target.addClass('is-close')
            $target.removeClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': true })
          }
          $btn.addClass('is-close')
          $btn.removeClass('is-open')
        } else {
          $btn.attr('aria-expanded', true)
          if (isClass) {
            $target.addClass('is-open')
            $target.removeClass('is-close')
          } else {
            $target.attr({ 'aria-hidden': false })
          }
          $btn.removeClass('is-close')
          $btn.addClass('is-open')
        }
      }
    }
  })

  $(document).on('click', '.js-accordion_close', function (e) {
    e.preventDefault()
  })
}

function accordionReset() {
  $('.js-accordion_btn').each(function (i) {
    var $btn = $(this)
    var $wrap = $btn.closest('.js-accordion')
    var isClass = $wrap.attr('data-isclass') === 'true'
    var responsive = $wrap.attr('data-accordion-responsive')
    var aria = eval('(' + responsive + ')')
    var targetId = $btn.attr('data-target')
    var $target = (function () {
      if (targetId) {
        return $('#' + targetId)
      } else {
        targetId = $wrap.find('.js-accordion_contents').attr('id')
        return $wrap.find('.js-accordion_contents')
      }
    })()

    var set = function (query, name) {
      if (component.matchMedia[query].matches) {
        var _open = function () {
          if (isClass) {
            $target.addClass('is-open')
            $target.removeClass('is-close')
          } else {
            $target.attr({ 'aria-hidden': false })
          }
          $btn.attr({ 'aria-expanded': true })
        }
        var _close = function () {
          if (isClass) {
            $target.addClass('is-close')
            $target.removeClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': true })
          }
          $btn.attr({ 'aria-expanded': false })
        }
        if ($btn.hasClass('is-open')) {
          _open()
        } else if ($btn.hasClass('is-close') && aria[name] !== null) {
          _close()
        } else if (aria[name] === null || aria[name] === true) {
          _open()
        } else if (aria[name] === false) {
          _close()
        }

        if (aria[name] === null) {
          $wrap.addClass('is-accordion-null')
          $btn.attr('disabled', true)
        } else {
          $wrap.removeClass('is-accordion-null')
          $btn.removeAttr('disabled')
        }
      }
    }

    if (typeof aria !== 'undefined') {
      for (let index = 0; index < mqList.length; index++) {
        set(mqList[index].query, mqList[index].name)
      }
    }
  })
}

function accordionInit() {
  $('.js-accordion_btn').each(function (i) {
    var $btn = $(this)
    var $wrap = $btn.closest('.js-accordion')

    if ($wrap.hasClass('is-initialized')) return

    var isClass = $wrap.attr('data-isclass') === 'true'
    var responsive = $wrap.attr('data-accordion-responsive')
    var aria = eval('(' + responsive + ')')
    var targetId = $btn.attr('data-target')
    var isTargetNullId = false
    var $target = (function () {
      if (targetId) {
        return $('#' + targetId)
      } else {
        isTargetNullId = true
        if ($wrap.find('.js-accordion_contents').eq(0).attr('id')) {
          targetId = $wrap.find('.js-accordion_contents').eq(0).attr('id')
        } else {
          targetId = 'a11y-accordion-contents' + i
          $wrap.find('.js-accordion_contents').eq(0).attr('id', targetId)
        }
        return $wrap.find('.js-accordion_contents').eq(0)
      }
    })()

    var set = function (query, name) {
      if (component.matchMedia[query].matches) {
        if (aria[name] === null) {
          if (isClass) {
            $target.addClass('is-open')
          } else {
            $target.attr({ 'aria-hidden': false })
          }
          $btn.attr({ 'aria-expanded': true })
          $wrap.addClass('is-accordion-null')
          $btn.attr('disabled', true)
        } else {
          if (isClass) {
            $target.addClass(aria[name] ? 'is-open' : 'is-close')
          } else {
            $target.attr({ 'aria-hidden': !aria[name] })
          }
          $btn.attr({ 'aria-expanded': aria[name] })
          $wrap.removeClass('is-accordion-null')
          $btn.removeAttr('disabled')
        }
      }
    }

    if ($target[0]) {
      var btnId = $btn.attr('id') ? $btn.attr('id') : 'a11y-accordion-btn' + i

      $btn.attr('aria-controls', targetId)
      $btn.attr('id', btnId)

      if (typeof aria !== 'undefined') {
        for (let index = 0; index < mqList.length; index++) {
          set(mqList[index].query, mqList[index].name)
        }
      } else {
        if (
          $wrap.hasClass('is-open') ||
          $btn.hasClass('is-open') ||
          $btn.attr('aria-expanded') === 'true' ||
          $target.attr('aria-hidden') === 'false'
        ) {
          $btn.attr('aria-expanded', true)
          $target.attr('aria-hidden', false)
        } else {
          $btn.attr('aria-expanded', false)
          $target.attr('aria-hidden', true)
        }
      }

      $wrap.addClass('is-initialized')
    }
  })
}

function ready() {
  component.matchMedia['--to-md'].addListener(function (mql) {
    if (mql.matches) accordionReset()
  })
  component.matchMedia['--md-lg'].addListener(function (mql) {
    if (mql.matches) accordionReset()
  })
  component.matchMedia['--lg'].addListener(function (mql) {
    if (mql.matches) accordionReset()
  })

  accordionInit()

  window.accordionInit = accordionInit
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
  accordionInit: accordionInit,
}
