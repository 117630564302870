import $ from 'jquery'

function init() {}
function ready() {
  const $form = $('.js-required-check-form').closest('form')

  if ($form[0]) {
    $('.js-required').attr('required', true)

    const $button = $form.find('.js-submit')
    const $checkboxGroups = $form.find('.js-required-checkbox-group')

    const checkAllCheckboxGroupChecked = () => {
      let allChecked = true
      $checkboxGroups.each((_, el) => {
        if (!$(el).find('input:checked')[0]) {
          allChecked = false
        }
      })
      return allChecked
    }

    const updateSubmitButton = () => {
      const requiredInputFilled = $form.get(0).checkValidity()
      const allCheckboxGroupChecked = checkAllCheckboxGroupChecked()
      if (requiredInputFilled && allCheckboxGroupChecked) {
        $button.prop('disabled', false)
      } else {
        $button.prop('disabled', true)
      }
    }

    $form.on('change', () => {
      updateSubmitButton()
    })
    updateSubmitButton()
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
