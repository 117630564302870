import $ from 'jquery'

function init() {}
function ready() {
  const $slider = $('.js-top-mv-slider')
  $slider.slick({
    slidesToShow: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2500,
    infinite: true,
    arrows: false,
    dots: true,
  })
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
