import $ from 'jquery'

function init() {}
function ready() {
  const $slider = $('.js-works-slider')
  $slider.slick({
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 4000,
    variableWidth: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow:
      '<button type="button" class="p-works-slider__arrow _prev"></button>',
    nextArrow:
      '<button type="button" class="p-works-slider__arrow _next"></button>',
    centerMode: true,
  })
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
