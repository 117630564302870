import $ from 'jquery'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function init() {}
function ready() {
  const onEnterTarget = (el) => {
    el.classList.add('is-active')

    const $worksSlider = $(el).find('.js-works-slider')
    if ($worksSlider[0]) {
      setTimeout(()=>{
        $worksSlider.slick('slickNext')
      }, 300)
    }
  }

  const scrollAnim = () => {
    const $targets = $('.js-scroll-anim')
  
    $targets.each((_, el) => {
      ScrollTrigger.create({
        // markers: true,
        trigger: el,
        start: 'top 50%',
        onEnter: () => onEnterTarget(el),
      })
    })
  }

  scrollAnim()

  window.scrollAnim = scrollAnim;
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
