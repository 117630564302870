import $ from 'jquery'
import { throttle, debounce } from 'throttle-debounce'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import component from './_component'
import focusLoopStack from './_focusLoopStack.js'

var close = function ($btn, $target, type) {
  $(window).off('.popup-toggle')
  $(document).off('.popup-toggle')
  $btn.removeClass('is-active')
  $btn.attr({ 'aria-expanded': 'false' })
  $target.attr({ 'aria-hidden': 'true' })

  var toggleType = $btn.attr('data-toggle-type')

  if (!type) {
    type = toggleType
  }

  enableBodyScroll($target[0])
  removeMMlistener()
  $btn.focus()

  if (type === 'fade') {
    $target.fadeOut()
  } else if (type === 'slide') {
    $target.fadeOut()
  } else if (type === 'sp-left-drawer') {
    $target.css({
      transition: 'transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)',
    })
    $target.css({ transform: 'translateX(0)' })
    $target.one(component.transitionEndEvents, function () {
      $target.css({ transform: '', transition: '' })
      $target.hide()
    })
    setTimeout(function () {
      $target.css({ transform: 'translateX(-100%)' })
    }, 10)
  } else {
    $target.hide()
  }

  if (component.matchMedia['--to-lg'].matches) {
    $btn.attr('disabled', false)
  }

  // フォーカスループコンテンツクリア
  focusLoopStack.delById('languagePopupMenu')
}

var escClose = function () {
  var modal = focusLoopStack.stack.pop()
  var $currentTarget = $(modal.targetEl)
  var $thisBtn = $(modal.openBtnEl)

  if (component.matchMedia['--to-lg'].matches) {
    close($thisBtn, $currentTarget)
  } else {
    close($thisBtn, $currentTarget, 'fade')
  }
}

var posSet = function ($btn, $target) {
  var thisRect = $btn[0].getBoundingClientRect()
  var r = thisRect.right
  var t = thisRect.top
  var tw = $target.width()
  var ww = $(window).width()
  //var posL = r - tw;
  var posR = ww - r

  $target.css({
    top: t + $btn.outerHeight() + 10,
    //left: posL < 0 ? 0 : posL,
    right: posR,
  })
}

var onResize = function ($btn, $target) {
  $(window).on(
    'resize.popup-toggle',
    debounce(100, function () {
      posSet($btn, $target)
    })
  )
}

var onOutsideClick = function ($btn, $target) {
  $(document).on('click.popup-toggle', function (e) {
    if (
      !$.contains($target[0], e.target) &&
      $target[0] !== e.target &&
      !$.contains($btn[0], e.target) &&
      $btn[0] !== e.target
    ) {
      close($btn, $target, 'fade')
    }
  })
}

var closefunc
var addMMlistener = function ($btn, $target) {
  closefunc = function () {
    close($btn, $target)
  }
  component.matchMedia['--lg'].addListener(closefunc)
}
var removeMMlistener = function () {
  component.matchMedia['--lg'].removeListener(closefunc)
}

function init() {
  $(document).on('click', '.js-popup-toggle_close', function (e) {
    var $target = $(this).closest('.js-popup-toggle_target')
    var $btn = $target.data('data-controls-el')
    close($btn, $target)
  })

  $(document).on('click', '.js-popup-toggle_open', function (e) {
    e.preventDefault()
    var $btn = $(this)
    var $target = $('#' + $btn.attr('aria-controls'))
    var toggleType = $btn.attr('data-toggle-type')
    $(document).off('keydown.popup-toggle')

    if ($btn.hasClass('is-active')) {
      close($btn, $target, 'fade')
    } else {
      $btn.addClass('is-active')
      $btn.attr({ 'aria-expanded': 'true' })
      $target.attr({ 'aria-hidden': 'false' })
      $target.data({ 'data-controls-el': $btn })

      if (component.matchMedia['--to-lg'].matches) {
        if (toggleType === 'sp-left-drawer') {
          $target.css({
            transition: 'transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)',
          })
          $target.css({ transform: 'translateX(-100%)' })
          $target.show()
          $target.one(component.transitionEndEvents, function () {
            $target.css({ transform: '', transition: '' })
          })
          setTimeout(function () {
            $target.css({ transform: 'translateX(0)' })
          }, 10)
        } else {
          $target.fadeIn()
        }

        $btn.attr('disabled', true)
      } else {
        $target.show().css({ opacity: 0 })
        setTimeout(function () {
          posSet($btn, $target)
        }, 0)
        $target.stop(true, true).animate({ opacity: 1 }, 300)
      }

      onResize($btn, $target)
      onOutsideClick($btn, $target)
      addMMlistener($btn, $target)

      focusLoopStack.stack.push({
        id: 'languagePopupMenu',
        type: 'languagePopup',
        targetEl: $target[0],
        openBtnEl: $btn[0],
        afterFocusEl: null,
        addFocus: [
          {
            el: $btn[0],
            matchMedia: '--md',
          },
        ],
        closeFunction: escClose,
      })
      disableBodyScroll($target[0])
    }
  })
}
function ready() {}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
