import $ from 'jquery'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import getUniqueStrId from '../_helper/_getUniqueStrId'
import focusLoopStack from './_focusLoopStack.js'

function init() {
  var modalClose = function (_targetNameId) {
    var modal = (function () {
      if (_targetNameId) {
        return focusLoopStack.getAndDelById(_targetNameId)
      } else {
        return focusLoopStack.stack.pop()
      }
    })()
    var currentTarget = modal.targetEl
    var focusAfterClosed = modal.focusAfterClosed
    var isScrollEnable = modal.isScrollEnable
    var targetNameId = modal.id
    var modalTypeLength = focusLoopStack.getTypeLength('modal')
    var $currentTarget = $(currentTarget)

    $currentTarget.animate(
      {
        opacity: 0,
      },
      300,
      function () {
        $currentTarget.removeClass('is-active').css({
          opacity: '',
        })
        if (modalTypeLength === 0) {
          $('html').removeClass('is-modal-active')
          $('html').removeClass('is-modal-scroll-enable')
        }

        if (focusAfterClosed) {
          focusAfterClosed.focus()
        }

        // clearAllBodyScrollLocks();
        if (!isScrollEnable) enableBodyScroll($currentTarget[0])

        // 英語トップスクロール制御
        if (window.enTopScroll) {
          window.enTopScroll()
        }
      }
    )

    $currentTarget.off(`click.${targetNameId}`)
  }

  $(document).on('click', '.js-modal_btn', function (e) {
    e.preventDefault()

    var $btn = $(this)
    var id = $btn.attr('id')
    var target = $btn.attr('data-target')
    var targetNameId = `modal-${target}`
    var $target = $(target)
    var focusAfterClosed = this
    var focusFirst = $btn.attr('data-focus-first')
    var isScrollEnable = $btn.attr('data-scroll-enable') === 'true'

    if ($target[0]) {
      // 開いたモーダル記録
      /*
      {
        id: string
        type: string
        targetEl: HTMLElement
        openBtnEl: HTMLElement
        afterFocusEl: HTMLElement
        addFocus: {
          el: HTMLElement
          matchMedia: --to-md | --md | --lg | etc
        } <Array>
        closeFunction: function
      }
      */
      focusLoopStack.stack.push({
        id: targetNameId,
        type: 'modal',
        targetEl: $target[0],
        openBtnEl: $btn[0],
        afterFocusEl: focusAfterClosed,
        addFocus: [],
        closeFunction: modalClose,
        isScrollEnable: isScrollEnable,
      })

      $('html').addClass('is-modal-active')
      if (isScrollEnable) $('html').addClass('is-modal-scroll-enable')

      $target.addClass('is-active').css({
        opacity: 0,
      })
      $target.animate(
        {
          opacity: 1,
        },
        300,
        function () {
          $target.css({
            opacity: '',
          })
        }
      )

      if ($target.find(focusFirst)[0]) {
        $target.find(focusFirst)[0].focus()
      } else {
        if ($target.find('.js-modal_close')[0]) {
          $target.find('.js-modal_close')[0].focus()
        } else {
          $target.find(':_focusable')[0].focus()
        }
      }

      if (!isScrollEnable) {
        disableBodyScroll($target[0])
      }

      $target.on(`click.${targetNameId}`, '.js-modal_close', function (e) {
        e.preventDefault()
        modalClose(targetNameId)
      })
      $target.on(`click.${targetNameId}`, '.js-modal_overlay', function (e) {
        e.preventDefault()
        modalClose(targetNameId)
      })
    }
  })
}

function ready() {
  // セットアップ
  window.addEventListener('DOMContentLoaded', function () {
    const modalLists = document.querySelectorAll(
      '.js-modal_btn:not(.is-initialized)'
    )
    for (let i = 0; i < modalLists.length; i++) {
      const modalBtn = modalLists[i]
      const btnDataTarget = modalBtn.getAttribute('data-target')
      const btnId = modalBtn.getAttribute('id')
      const contents = document.querySelector(btnDataTarget)

      if (contents && contents.classList.contains('js-modal_contents')) {
        if (!btnId) {
          btnId = getUniqueStrId('a11y-modal')
          modalBtn.setAttribute('id', btnId)
        }
        contents.setAttribute('aria-modal', true)
        contents.setAttribute('role', 'dialog')

        modalBtn.classList.add('is-initialized')
      }
    }
  })
}

export default {
  init() {
    init()
    ready()
  },
}
