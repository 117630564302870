import $ from 'jquery'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import component from './_import/_component'
import base from './_import/_base'
import matchMedia from './_import/_match-media'
import pagetop from './_import/_pagetop'
import anchorLink from './_import/_anchor-link'
import tab from './_import/_tab'
import accordion from './_import/_accordion'
import modal from './_import/_modal'
import globalNav from './_import/_global-nav'
import isScrollbar from './_import/_isScrollbar'
import popupToggle from './_import/_popup-toggle'
import filterTab from './_import/_filter-tab'
import scrollhint from './_import/_scrollhint.js'
import topAnimation from './_import/_top-animation.js'
import worksSlider from './_import/_works-slider.js'
import topMvSlider from './_import/_top-mvslider.js'
import scrollAnimation from './_import/_scroll-animation.js'
import inputFile from './_import/_input-file.js'
import handleSubmitButton from './_import/_handle-submit-button.js'
import cookiePopup from './_import/_cookie-popup.js'
import enTopScroll from './_import/_en-top-scroll.js'

component.set(base)
component.add('matchMedia', matchMedia)

$(function () {
  gsap.registerPlugin(ScrollTrigger)
})

pagetop.init()
globalNav.init()
anchorLink.init()
tab.init()
accordion.init()
modal.init()
isScrollbar.init()
popupToggle.init()
filterTab.init()
scrollhint.init()
topAnimation.init()
worksSlider.init()
topMvSlider.init()
scrollAnimation.init()
inputFile.init()
handleSubmitButton.init()
cookiePopup.init()
enTopScroll.init()

component.add('tab', tab)
component.add('modal', modal)
component.add('accordion', accordion)

const init = () => {
  $('html').removeClass('no-js')
}

init()

$(function () {})
