export default {
  easeIn: 'easeInQuad',
  easeOut: 'easeOutQuad',

  transitionEndEvents: [
    'webkitTransitionEnd.transitionend',
    'mozTransitionEnd.transitionend',
    'oTransitionEnd.transitionend',
    'transitionend.transitionend',
  ].join(' '),

  loopFocusableIntercept: 0,
}
