import $ from 'jquery'
import { gsap } from 'gsap'

function init() {}
function ready() {
  if (!($('html').hasClass('page-top'))) {
    return
  }
  
  const texts = document.querySelector('.js-top-anim__texts')
  const mainCirclesContainer = document.querySelectorAll(
    '.js-top-anim__main-circles-container'
  )
  const mainCircles = document.querySelectorAll('.js-top-anim__main-circle')
  const [mainCircle1, mainCircle2, mainCircle3, mainCircle4] =
    Array.from(mainCircles)

  const lines = document.querySelectorAll('.js-top-anim__line')
  const [line1, line2, line3, line4] = Array.from(lines)

  const animCirclesContainer = document.querySelectorAll(
    '.js-top-anim__anim-circles-container'
  )
  const animCircles = document.querySelectorAll('.js-top-anim__anim-circle')
  const [animCircle1, animCircle2, animCircle3, animCircle4] =
    Array.from(animCircles)

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.js-top-anim-container',
      start: 'top+=30% center',
    },
  })

  gsap.to(animCircle1, { x: 100, y: -150, scale: 0.3, duration: 0 })
  gsap.to(animCircle2, { x: -100, y: -200, scale: 0.8, duration: 0 })
  gsap.to(animCircle3, { x: 100, y: 200, scale: 0.7, duration: 0 })
  gsap.to(animCircle4, { x: -100, y: 250, scale: 0.4, duration: 0 })

  tl.to(animCircle1, { x: 0, y: 0, duration: 1 }, 0)
    .to(animCircle1, { opacity: 1, duration: 0.6 }, 0)
    .to(animCircle1, { opacity: 0, duration: 0.4 }, '>')
    .to(animCircle1, { scale: 0.5, duration: 0.5 }, 0)
    .to(animCircle1, { scale: 0, duration: 0.5 }, '>')

    .to(animCircle2, { x: 0, y: 0, duration: 1 }, 0)
    .to(animCircle2, { opacity: 1, duration: 0.6 }, 0)
    .to(animCircle2, { opacity: 0, duration: 0.4 }, '>')
    .to(animCircle2, { scale: 1, duration: 0.5 }, 0)
    .to(animCircle2, { scale: 0, duration: 0.5 }, '>')

    .to(animCircle3, { x: 0, y: 0, duration: 1 }, 0)
    .to(animCircle3, { opacity: 1, duration: 0.6 }, 0)
    .to(animCircle3, { opacity: 0, duration: 0.4 }, '>')
    .to(animCircle3, { scale: 1.2, duration: 0.5 }, 0)
    .to(animCircle3, { scale: 0, duration: 0.5 }, '>')

    .to(animCircle4, { x: 0, y: 0, duration: 1 }, 0)
    .to(animCircle4, { opacity: 1, duration: 0.6 }, 0)
    .to(animCircle4, { opacity: 0, duration: 0.4 }, '>')
    .to(animCircle4, { scale: 0.6, duration: 0.5 }, 0)
    .to(animCircle4, { scale: 0, duration: 0.5 }, '>')

    .to(animCirclesContainer, { autoAlpha: 0, duration: 0 }, '>')
    .to(mainCirclesContainer, { scale: 0.95, duration: 0 }, '<')

    .to(
      mainCirclesContainer,
      { opacity: 1, scale: 1, duration: 0.5, ease: 'Power4.out' },
      '>'
    )
    .from(
      mainCircle1,
      { x: '30%', y: '30%', duration: 0.4, ease: 'Power4.out' },
      '<'
    )
    .from(
      mainCircle2,
      { x: '-30%', y: '30%', duration: 0.4, ease: 'Power4.out' },
      '<'
    )
    .from(
      mainCircle3,
      { x: '-30%', y: '-30%', duration: 0.4, ease: 'Power4.out' },
      '<'
    )
    .from(
      mainCircle4,
      { x: '30%', y: '-30%', duration: 0.4, ease: 'Power4.out' },
      '<'
    )
    .to(texts, { opacity: 1, duration: 0.7 }, '>')
    .from(line1, { width: 0, duration: 0.6 }, '>-0.1')
    .from(line2, { width: 0, duration: 0.6 }, '>-0.1')
    .from(line3, { width: 0, duration: 0.6 }, '>-0.1')
    .from(line4, { width: 0, duration: 0.6 }, '>-0.1')
    .call(
      () => {
        lines.forEach((line) => {
          line.style.width = ''
        })
      },
      [],
      '>'
    )
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
