window.component = window.component || {}
let component = window.component

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) {
      // .length of function is 2
      'use strict'
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object')
      }

      var to = Object(target)

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index]

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true,
  })
}

function typeOf(obj) {
  const toString = Object.prototype.toString
  return toString.call(obj).slice(8, -1).toLowerCase()
}

component.set = (addObject) => {
  if (typeOf(addObject) === 'object') {
    component = Object.assign(component, addObject)
  }
}

component.add = (key, value) => {
  component[key] = value
}

export default component
