import $ from 'jquery'

function init() {}
function ready() {
  const $input = $('.js-input-file')
  $input.on('change', (e) => {
    const file = $(e.target).prop('files')[0]
    $(e.target).siblings('.js-input-file-name').text(file.name)
  })
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
