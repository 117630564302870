import component from './_component'

/*
@custom-media --sp only screen and (min-width: 0px) and (max-width: 767px);
@custom-media --md only screen and (min-width: 0px) and (max-width: 1024px);
@custom-media --tb only screen and (min-width: 768px) and (max-width: 1024px);
@custom-media --tbpc only screen and (min-width: 768px);
@custom-media --pc only screen and (min-width: 1025px);
@custom-media --large only screen and (min-width: 768px) and (max-width: 1300px);
@custom-media --xlarge only screen and (min-width: 1301px);

@custom-media --spmax only screen and (min-width: 0px) and (max-width: 768px);
@custom-media --pcmin only screen and (min-width: 769px) and (max-width: 1024px);
@custom-media --pcmax only screen and (min-width: 769px);


// new
@custom-media --hover (any-hover: hover);

@custom-media --sm only screen and (min-width: 576px);
@custom-media --md only screen and (min-width: 768px);
@custom-media --lg only screen and (min-width: 1024px);
@custom-media --xl only screen and (min-width: 1200px);
@custom-media --xxl only screen and (min-width: 1400px);

@custom-media --to-sm only screen and (max-width: 575px);
@custom-media --to-md only screen and (max-width: 767px);
@custom-media --to-lg only screen and (max-width: 1023px);
@custom-media --to-xl only screen and (max-width: 1199px);

@custom-media --sm-md only screen and (min-width: 576px) and (max-width: 767px);
@custom-media --md-lg only screen and (min-width: 768px) and (max-width: 1023px);
@custom-media --lg-xl only screen and (min-width: 1024px) and (max-width: 1199px);
@custom-media --xl-xxl only screen and (min-width: 1200px) and (max-width: 1399px);


*/

const matchMedia = {
  '--sm': window.matchMedia('(min-width: 576px)'),
  '--md': window.matchMedia('(min-width: 768px)'),
  '--lg': window.matchMedia('(min-width: 1024px)'),
  '--xl': window.matchMedia('(min-width: 1200px)'),
  '--xxl': window.matchMedia('(min-width: 1400px)'),

  '--to-sm': window.matchMedia('(max-width: 575px)'),
  '--to-md': window.matchMedia('(max-width: 767px)'),
  '--to-lg': window.matchMedia('(max-width: 1023px)'),
  '--to-xl': window.matchMedia('(max-width: 1199px)'),

  '--sm-md': window.matchMedia('(min-width: 576px) and (max-width: 767px)'),
  '--md-lg': window.matchMedia('(min-width: 768px) and (max-width: 1023px)'),
  '--lg-xl': window.matchMedia('(min-width: 1024px) and (max-width: 1199px)'),
  '--xl-xxl': window.matchMedia('(min-width: 1200px) and (max-width: 1399px)'),

  '--no-match': window.matchMedia('(max-width: 0px)'),
  '--all-match': window.matchMedia('(min-width: 0px)'),
}

export default matchMedia
