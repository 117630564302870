/**
 * Focus Loopを行うコンテンツを格納する
 * focusLoopStackにデータがある場合のみtabキーによるフォーカスをターゲットコンテンツ内に閉じ込める
 * Escキーでターゲットコンテンツを閉じる処理を行う
 */

let focusLoopStack = []
/*
{
  id: string
  type: string
  targetEl: HTMLElement
  openBtnEl: HTMLElement
  afterFocusEl: HTMLElement
  addFocus: {
    el: HTMLElement
    matchMedia: --to-md | --md | --lg | etc
  } <Array>
  closeFunction: function
}
*/

var focusMove = function (e) {
  var focusLoop = focusLoopStack[focusLoopStack.length - 1]
  var id = focusLoop.id
  var $currentTarget = $(focusLoop.targetEl)
  var $currentTargetFocusable = $currentTarget.find(':_focusable')
  var $currentTargetFocusableAdd = $currentTargetFocusable

  if ($currentTarget[0]) {
    // ターゲットコンテンツ以外にフォーカスを回す要素を追加する
    if (focusLoop.addFocus && focusLoop.addFocus.length) {
      for (let index = 0; index < focusLoop.addFocus.length; index++) {
        const $el = $(focusLoop.addFocus[index].el)
        if ($el[0]) {
          // メディアクエリでaddFocusを切り替える
          if (focusLoop.addFocus[index].matchMedia) {
            if (
              component.matchMedia[focusLoop.addFocus[index].matchMedia] &&
              component.matchMedia[focusLoop.addFocus[index].matchMedia].matches
            ) {
              $currentTargetFocusableAdd = $currentTargetFocusableAdd.add($el)
            }
          } else {
            $currentTargetFocusableAdd = $currentTargetFocusableAdd.add($el)
          }
        }
      }
    }

    var focusLen = $currentTargetFocusableAdd.length
    var thisFocusIndex = $currentTargetFocusableAdd.index($(':focus'))
    var next = thisFocusIndex

    var $firstEl = $currentTargetFocusableAdd.first()
    var $lastEl = $currentTargetFocusableAdd.last()
    var activeElement = document.activeElement

    // choromeでクリック後のTabキーでfocus()制御を行うと:focus-visible判定がでないので対処
    // ただし、外部フォーカス要素からターゲット内に移動するときはfocus()が必要になり、初回は:focus-visible判定にならない

    // どうやらクリック後、e.preventDefaultさせずにキー操作を通す必要がある？

    if (e.shiftKey) {
      next--
      next = next < 0 ? focusLen - 1 : next

      // prevはすべてfocus()対応
      if ($currentTargetFocusableAdd[next]) {
        e.preventDefault()
        $currentTargetFocusableAdd[next].focus()
      }
    } else if (!e.shiftKey) {
      next++
      next = next >= focusLen ? 0 : next

      // nextは$currentTarget外ならfocus()
      if (
        !$currentTargetFocusable.filter((index, el) => el === activeElement)[0]
      ) {
        // 外側
        if ($currentTargetFocusableAdd[next]) {
          // chrome, 初回のこのタイミングは:focus-visible判定がでない
          e.preventDefault()
          $currentTargetFocusableAdd[next].focus()
        }
      } else {
        // $currentTarget内、且つ最終要素以外なら制御無し
        if ($lastEl[0] === activeElement && !e.shiftKey) {
          e.preventDefault()
          $firstEl[0].focus()
        }
      }
    }
  }
}

$(document).on('keyup.focusLoopStack', function (e) {})
$(document).on('keydown.focusLoopStack', function (e) {
  if (focusLoopStack.length) {
    var focusLoop = focusLoopStack[focusLoopStack.length - 1]
    if (e.key === 'Tab') {
      focusMove(e)
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      if (
        focusLoop &&
        focusLoop.closeFunction &&
        typeof focusLoop.closeFunction === 'function'
      ) {
        focusLoop.closeFunction()
      }
    }
  }
})

export default {
  stack: focusLoopStack,
  delById: function (id) {
    for (let index = 0; index < focusLoopStack.length; index++) {
      const element = focusLoopStack[index]
      if (element.id === id) {
        focusLoopStack.splice(index, 1)
        break
      }
    }
  },
  getAndDelById: function (id) {
    let ret = []
    for (let index = 0; index < focusLoopStack.length; index++) {
      const element = focusLoopStack[index]
      if (element.id === id) {
        ret = focusLoopStack.splice(index, 1)
        break
      }
    }
    return ret.length ? ret[0] : null
  },
  getTypeLength: function (typeName) {
    let ret = 0
    for (let index = 0; index < focusLoopStack.length; index++) {
      const element = focusLoopStack[index]
      if (element.type === typeName) {
        ret++
      }
    }
    return ret
  },
}
