import component from './_component'

function init() {}
function ready() {
  const isTouchDevice = () => {
    return (
      !!(
        typeof window !== 'undefined' &&
        ('ontouchstart' in window ||
          (window.DocumentTouch &&
            typeof document !== 'undefined' &&
            document instanceof window.DocumentTouch))
      ) ||
      !!(
        typeof navigator !== 'undefined' &&
        (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
      )
    )
  }
  if (!$('html').hasClass('page-en-top')) {
    return
  }
  if (isTouchDevice()) {
    return
  }

  const mq = component.matchMedia['--md']

  let startTime, startScrollY, scrollTo, duration
  let scrollFlug = true

  const setVars = () => {
    startTime = Date.now()
    startScrollY = window.scrollY
    scrollTo =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    duration = (scrollTo - startScrollY) * 50
  }

  const scrollAnimation = () => {
    if (
      mq.matches &&
      duration !== 0 &&
      !$('html').hasClass('is-modal-active')
    ) {
      const progress = Math.min(1, (Date.now() - startTime) / duration)
      const scrollY = startScrollY + (scrollTo - startScrollY) * progress
      window.scrollTo(0, scrollY)
      if (progress < 1 && scrollFlug) {
        requestAnimationFrame(scrollAnimation)
      }
    }
  }

  setVars()
  scrollAnimation()

  $(document).on('wheel', () => {
    if (scrollFlug) {
      scrollFlug = false
      setTimeout(() => {
        scrollFlug = true
        setVars()
        scrollAnimation()
      }, 1000)
    }
  })

  mq.onchange = (e) => {
    if (e.matches) {
      setVars()
      scrollAnimation()
    }
  }

  window.enTopScroll = () => {
    setVars()
    scrollAnimation()
  }
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
