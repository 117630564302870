import getUniqueStrId from '../_helper/_getUniqueStrId'

/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   tabs-automatic.js
 *
 *   Desc:   Tablist widget that implements ARIA Authoring Practices
 */
;('use strict')

// !!!![role=tablist]で動作するとslickなどにも反応するNG

class TabsAutomatic {
  constructor(groupNode) {
    this.tablistNode = groupNode

    this.tabs = []

    this.firstTab = null
    this.lastTab = null

    this.tabs = Array.from(this.tablistNode.querySelectorAll('[role=tab]'))

    // toggle対応用
    /*
    this.toggleTabs = [];
    var closestTabsWrap = this.tablistNode.closest('.c-tabs');
    var closestTablistId = this.tablistNode.id;
    if (closestTablistId) {
      this.toggleTabs = Array.from(closestTabsWrap.querySelectorAll(`[role=tab][aria-owns=${closestTablistId}]`));
    }
    */
    // toggle対応用ここまで

    this.tabpanels = []

    let initialTab = null

    this.setInitId()

    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i]
      const tabpanel = document.getElementById(
        tab.getAttribute('aria-controls')
      )

      tab.tabIndex = -1

      if (tab.getAttribute('aria-selected') === 'true') {
        initialTab = tab
      }

      tab.setAttribute('aria-selected', 'false')
      this.tabpanels.push(tabpanel)

      tab.addEventListener('keydown', this.onKeydown.bind(this))
      tab.addEventListener('click', this.onClick.bind(this))

      if (!this.firstTab) {
        this.firstTab = tab
      }
      this.lastTab = tab
    }

    if (initialTab) {
      this.setSelectedTab(initialTab, false)
    } else {
      this.setSelectedTab(this.firstTab, false)
    }
  }

  setInitId() {
    for (let index = 0; index < this.tabs.length; index++) {
      const btnId = getUniqueStrId('tab-btns')
      const tabpanel = document.getElementById(
        this.tabs[index].getAttribute('aria-controls')
      )

      if (!this.tabs[index].id) {
        this.tabs[index].id = btnId
        tabpanel.setAttribute('aria-labelledby', btnId)
      }
    }
  }

  setSelectedTab(currentTab, setFocus) {
    if (typeof setFocus !== 'boolean') {
      setFocus = true
    }
    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i]

      if (currentTab === tab) {
        tab.setAttribute('aria-selected', 'true')
        tab.removeAttribute('tabindex')
        this.tabpanels[i].hidden = false
        if (setFocus) {
          tab.focus()
        }
      } else {
        tab.setAttribute('aria-selected', 'false')
        tab.tabIndex = -1
        this.tabpanels[i].hidden = true
      }
    }
  }

  getNonDisabledTabList() {
    return this.tabs
      .map((tab, index) => {
        return {
          isDisabled: tab.disabled,
          index: index,
        }
      })
      .filter((tab) => !tab.isDisabled)
  }

  getNextIndex(currentTab, isNext) {
    const index = this.tabs.indexOf(currentTab)
    const maxIndex = this.tabs.length - 1
    let nextIndex = -1
    let isNextIndex = false
    let cntNum = 0

    if (isNext) {
      nextIndex = currentTab === this.lastTab ? 0 : index + 1
    } else {
      nextIndex = currentTab === this.firstTab ? maxIndex : index - 1
    }

    while (cntNum < maxIndex) {
      // 次がdisabledでなければ
      if (!this.tabs[nextIndex].disabled) {
        isNextIndex = true
        break
      }

      // 次がdisabledならさらに次のタブ
      if (isNext) {
        nextIndex = this.tabs[nextIndex] === this.lastTab ? 0 : ++nextIndex
      } else {
        nextIndex =
          this.tabs[nextIndex] === this.firstTab ? maxIndex : --nextIndex
      }

      // すべてdisabledの場合ループを止める
      cntNum++
    }

    if (!isNextIndex) {
      nextIndex = -1
    }

    return nextIndex
  }

  setSelectedToPreviousTab(currentTab) {
    const nextIndex = this.getNextIndex(currentTab, false)
    if (nextIndex !== -1) {
      this.setSelectedTab(this.tabs[nextIndex])
    }
  }

  setSelectedToNextTab(currentTab) {
    const nextIndex = this.getNextIndex(currentTab, true)
    if (nextIndex !== -1) {
      this.setSelectedTab(this.tabs[nextIndex])
    }
  }

  setSelectedToFirstTab() {
    const nonDisabledTabList = this.getNonDisabledTabList()
    if (nonDisabledTabList.length) {
      this.setSelectedTab(this.tabs[nonDisabledTabList[0].index])
    }
  }
  setSelectedToLastTab() {
    const nonDisabledTabList = this.getNonDisabledTabList()
    if (nonDisabledTabList.length) {
      this.setSelectedTab(
        this.tabs[nonDisabledTabList[nonDisabledTabList.length - 1].index]
      )
    }
  }

  /* EVENT HANDLERS */

  onKeydown(event) {
    const tgt = event.currentTarget
    let flag = false

    switch (event.key) {
      case 'ArrowLeft':
        this.setSelectedToPreviousTab(tgt)
        flag = true
        break

      case 'ArrowRight':
        this.setSelectedToNextTab(tgt)
        flag = true
        break

      case 'Home':
        this.setSelectedTab(this.firstTab)
        flag = true
        break

      case 'End':
        this.setSelectedTab(this.lastTab)
        flag = true
        break

      default:
        break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  onClick(event) {
    this.setSelectedTab(event.currentTarget)
  }
}

// Initialize tablist

function ready() {
  window.addEventListener('DOMContentLoaded', function () {
    // const tablists = document.querySelectorAll('[role=tablist].automatic');
    const tablists = document.querySelectorAll(
      '[role=tablist]:not(.is-initialized)'
    )
    for (let i = 0; i < tablists.length; i++) {
      new TabsAutomatic(tablists[i])
      tablists[i].classList.add('is-initialized')
    }
  })
}

export default {
  init() {
    ready()
  },
}
